<template>
  <v-tooltip max-width="500" bottom color="white" :key="refresh" open-delay="1000" v-if="conteudo != null">
    <template v-slot:activator="{ on, attrs }" class="">
      <span v-bind="attrs" v-on="on">
        <span v-if="(index%2)==0" v-cHex="hexShades(area.cor,0.1)" class="me-1 rounded p-0" :style="'background-color:'+hexTints(area.cor,.98)">{{conteudo.texto}}.</span>
        <span v-if="(index%2)==1" v-cHex="hexTints(area.cor,0.2)" class="me-1 rounded p-0" :style="'background-color:'+hexTints(area.cor,.98)">{{conteudo.texto}}.</span>
      </span>
    </template>
    <v-card class="p-1" outlined>
      <p class="m-0 p-1 line-height-1" :style="'background-color:'+hexTints(area.cor,0.87)">
        <span class="ms-1 fs-8pt fw-400">Conteúdo</span>
        <span class="ms-1 fs-8pt fw-800">ID:</span>
        <span class="ms-1 fs-8pt fw-600 f-lato">{{conteudo.id}}</span>
      </p>
      <p class="m-0 p-1 line-height-1 fs-10pt f-raleway" :style="'background-color:'+hexTints(area.cor,0.97)">
        {{conteudo.texto}}
      </p>
    </v-card>
    <v-card class="p-1" v-if="ucs != null" outlined>
      <p class="m-0 p-1 mb-0 line-height-1" :style="'background-color:'+hexTints(area.cor,0.99)">
        <span class="ms-1 fs-8pt fw-500">indicado em:</span>
      </p>
      <div class="px-1">
        <uclabel :ucID="item.id" v-for="(item,key,index) in ucs" :key="key+index+refresh"/>
      </div>
    </v-card>
  </v-tooltip>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "",
  components: {
    'uclabel': () => import('./uc-label.vue'),
  },
  props: {
    area: { default: null, type: Object },
    conteudo: { default: null, type: Object },
    index: { default: 0, type: Number }
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      ucs: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
      if(this.conteudo != null) {
        rdb.ref("/curriculo/conteudos/conteudos/"+this.conteudo.id+"/ucs").get().then(function(snap) {
          self.ucs = snap.val();
          self.refresh++;
          console.log("self.ucs",self.ucs);
        });
      }
    }
  }
}
</script>

<style scoped>
</style>
